define('ember-carousel/components/carousel-item', ['exports', 'ember-carousel/templates/components/carousel-item'], function (exports, _carouselItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;
  exports.default = Component.extend({
    classNameBindings: [':carousel-item', 'isActive:active', 'slidingIn:slide-in', 'slidingOut:slide-out', 'from'],

    layout: _carouselItem.default,
    index: 0,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      get(this, 'register')(this);

      var allItems = this.get('allItems');

      set(this, 'index', allItems.indexOf(this));
    },


    isActive: computed('allItems.@each', function () {
      return this === get(this, 'allItems.firstObject');
    })
  });
});