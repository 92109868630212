define('ember-carousel/components/carousel-container', ['exports', 'ember-carousel/templates/components/carousel-container'], function (exports, _carouselContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var computed = Ember.computed;
  var get = Ember.get;
  var set = Ember.set;
  var reads = Ember.computed.reads;
  var run = Ember.run;
  var later = Ember.run.later;
  var A = Ember.A;
  exports.default = Component.extend({
    classNames: ['carousel-container'],

    layout: _carouselContainer.default,
    transitionInterval: 500,
    totalCarouselItems: reads('carouselItems.length'),

    init: function init() {
      this._super.apply(this, arguments);
      set(this, 'carouselItems', A());
    },


    activeCarouselItem: computed('carouselItems.{length,@each.isActive}', function () {
      return get(this, 'carouselItems').findBy('isActive');
    }),

    slide: function slide(newActiveIndex, direction) {
      var carouselItems = get(this, 'carouselItems');
      var activeCarouselItem = get(this, 'activeCarouselItem');
      var newActiveCarouselItem = carouselItems[newActiveIndex];
      var transitionInterval = get(this, 'transitionInterval');
      var transitionOffset = 50;

      run(function () {
        set(activeCarouselItem, 'from', direction);
        set(newActiveCarouselItem, 'from', direction);
      });

      later(function () {
        set(activeCarouselItem, 'slidingOut', true);
        set(newActiveCarouselItem, 'slidingIn', true);
      }, transitionOffset);

      later(function () {
        activeCarouselItem.setProperties({
          slidingOut: false,
          from: null,
          isActive: false
        });

        newActiveCarouselItem.setProperties({
          slidingIn: false,
          from: null,
          isActive: true
        });
      }, transitionInterval + transitionOffset);
    },


    actions: {
      slideRight: function slideRight() {
        var direction = 'right';
        var activeIndex = get(this, 'activeCarouselItem.index');
        var newActiveIndex = activeIndex - 1;

        if (activeIndex === 0) {
          newActiveIndex = get(this, 'totalCarouselItems') - 1;
        }

        if (get(this, 'onSlide')) {
          get(this, 'onSlide')({
            index: newActiveIndex,
            previousIndex: activeIndex,
            direction: direction
          });
        }

        this.slide(newActiveIndex, direction);
      },
      slideLeft: function slideLeft() {
        var direction = 'left';
        var activeIndex = get(this, 'activeCarouselItem.index');
        var newActiveIndex = activeIndex + 1;

        if (activeIndex === get(this, 'totalCarouselItems') - 1) {
          newActiveIndex = 0;
        }

        if (get(this, 'onSlide')) {
          get(this, 'onSlide')({
            index: newActiveIndex,
            previousIndex: activeIndex,
            direction: direction
          });
        }

        this.slide(newActiveIndex, direction);
      },
      registerItem: function registerItem(item) {
        this.get('carouselItems').pushObject(item);
      }
    }
  });
});