define('ember-cookie-consent-cnil/components/cookie-consent', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    // TODO need to be set in environnement !
    // This is the google ID given by google for a specific web site...
    gaProperty: 'UA-XXXXXXXX-Y',

    // Have the user choose to be follow or not ?
    hasConsent: false,

    // Must the bannere be show ?
    isBannerNeeded: false,

    init: function init() {
      this._super();
      var config = this.container.lookupFactory('config:environment');
      var gaProperty = config.ga_id;

      // check google id in environment:
      if (gaProperty != null) {
        this.set('gaProperty', gaProperty);
      } else {
        throw new Error('Google ID for this website need to be set in the "ga_id" environment variable.');
      }

      var dnt = navigator.doNotTrack || navigator.msDoNotTrack || window.doNotTrack;
      if (dnt ? dnt === "yes" || dnt === 1 || dnt === "1" : false) {
        console.log("DEBUG: 'donottrack' is activated.");
        // check it is donottrack !
        this.set('isBannerNeeded', false);
        this.set('hasConsent', false);
        this.destroy();
      } else if (navigator.userAgent.indexOf("Prerender") > -1) {
        console.log('DEBUG: it is the prerender.');
        // check it's the prerender !
        this.set('isBannerNeeded', false);
        this.set('hasConsent', false);
        this.destroy();
      } else if (navigator.cookieEnabled ? false : true) {
        console.log('DEBUG: can not write cookie.');
        this.set('isBannerNeeded', false);
        this.set('hasConsent', false);
        this.destroy();
      } else {
        var hasConsent = _ember['default'].$.cookie('hasConsent');
        if (!hasConsent) {
          this.set('isBannerNeeded', true);
          console.log('DEBUG: banner is needed.');
        } else {
          if (hasConsent === "true") {
            console.log('DEBUG: user consents.');
            this.set('hasConsent', true);
            _ember['default'].run(this, 'startGoogleAnalytics');
          } else {
            console.log('DEBUG: user declines.');
            this.set('hasConsent', false);
            _ember['default'].run(this, 'deleteGoogleAnalytics');
          }
        }
      }
    },

    startGoogleAnalytics: function startGoogleAnalytics() {
      var gas = document.createElement('script');
      gas.id = 'google_analytics';
      gas.type = 'text/javascript';
      gas.async = false;
      gas.text = "" + "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){" + "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o)," + "m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)" + "})(window,document,'script','//www.google-analytics.com/analytics.js','ga');" + "ga('create', '" + this.get('gaProperty') + "', 'auto');" + "ga('send', 'pageview');";
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gas, s);
      this.destroy();
    },

    deleteGoogleAnalytics: function deleteGoogleAnalytics() {
      // delete google script:
      var google_script = _ember['default'].$['google_analyics'];
      if (google_script) {
        google_script.remove();
      }
      // delete google cookie:
      var cookieNames = ["__utma", "__utmb", "__utmc", "__utmt", "__utmv", "__utmz", "_ga", "_gat"];
      for (var i = 0; i < cookieNames.length; i++) {
        _ember['default'].$.removeCookie(cookieNames[i]);
      }
      this.destroy();
    },

    handleOutsideClick: function handleOutsideClick(event) {
      var $element = this.$();
      var $target = _ember['default'].$(event.target);
      if ($element) {
        if (!$target.parents("#gaBanner").length) {
          console.log('click ouside component');
          if (this) {
            this.send('consent');
            this.destroy();
          }
        }
      }
    },

    setupOutsideClickListener: _ember['default'].on('didInsertElement', function () {
      var clickHandler = this.get('handleOutsideClick').bind(this);

      return _ember['default'].$(document).on('click', clickHandler);
    }),

    removeOutsideClickListener: _ember['default'].on('willDestroyElement', function () {
      var clickHandler = this.get('handleOutsideClick').bind(this);

      return _ember['default'].$(document).off('click', clickHandler);
    }),

    actions: {
      consent: function consent() {
        this.set('isBannerNeeded', false);

        // User is ok to be follow:
        var disableStr = 'ga-disable-' + this.get('gaProperty');
        _ember['default'].$.removeCookie(disableStr, 'true', { path: '/' });

        // 13 months in milliseconds..
        var cookieTimeout = 33696000000;
        var expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + cookieTimeout);
        //Write it too a permanent cookie (valid 13 months) to memorize it:
        _ember['default'].$.cookie("hasConsent", "true", { expires: expirationDate, path: '/' });
        _ember['default'].run(this, 'startGoogleAnalytics');
        this.destroy();
      },

      decline: function decline() {
        this.set('isBannerNeeded', false);

        // 13 months in milliseconds..
        var cookieTimeout = 33696000000;
        var expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + cookieTimeout);
        //Write it too a permanent cookie (valid 13 months) to memorize it:
        _ember['default'].$.cookie("hasConsent", "false", { expires: expirationDate, path: '/' });
        var disableStr = 'ga-disable-' + this.get('gaProperty');
        _ember['default'].$.cookie(disableStr, 'true', { path: '/' });
        _ember['default'].run(this, 'deleteGoogleAnalytics');
        this.destroy();
      },

      more: function more() {
        try {
          var router = this.container.lookup('router:main');
          router.transitionTo('mentionslegales');
        } catch (error) {
          alert("A 'mentionslegales' route has to be define to give informations to user. Error is " + error);
        }
      }
    }
  });
});